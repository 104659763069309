import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Channel, Session } from "../model";

interface SessionsState {
    isLoading: boolean;
    sessions: Session[];
    channels: Channel[];
}

const initialState: SessionsState = {
    isLoading: true,
    sessions: [],
    channels: [],
};

const sessionsSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {
        sessionsLoaded(state, action: PayloadAction<{ sessions: Session[]; channels: Channel[] }>) {
            state.isLoading = false;
            state.sessions = action.payload.sessions;
            state.channels = action.payload.channels;
        },
    },
});

export default sessionsSlice;
