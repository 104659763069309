import React from "react";
import { useFilterHiddenChannels, useFilterVisibleChannels } from "../hooks/filterHooks";
import { useDispatch } from "react-redux";
import filtersSlice from "../store/slices/filtersSlice";
import ChannelBadge from "./ChannelBadge/ChannelBadge";

export default function ChannelFilter() {
    const visibleChannels = useFilterVisibleChannels();
    const hiddenChannels = useFilterHiddenChannels();
    const dispatch = useDispatch();

    const toggleFilterChannel = (channelId: number) => dispatch(filtersSlice.actions.channelToggled(channelId));

    return (
        <section className="channel-filter">
            <div className="channel-filter__side">
                <span className="channel-filter__title">Angezeigte Kategorien</span>
                <ul className="channel-filter__list">
                    {visibleChannels.map((channel) => (
                        <li key={channel.id}>
                            <ChannelBadge
                                as="button"
                                channel={channel}
                                onClick={() => toggleFilterChannel(channel.id)}
                            />
                        </li>
                    ))}
                    {visibleChannels.length === 0 ? <span className="channel-filter__none">keine.</span> : null}
                </ul>
            </div>
            <div className="channel-filter__side">
                <span className="channel-filter__title">Ausgeblendete Kategorien</span>
                <ul className="channel-filter__list">
                    {hiddenChannels.map((channel) => (
                        <li key={channel.id}>
                            <ChannelBadge
                                as="button"
                                inActive
                                channel={channel}
                                onClick={() => toggleFilterChannel(channel.id)}
                            />
                        </li>
                    ))}
                    {hiddenChannels.length === 0 ? <span className="channel-filter__none">keine.</span> : null}
                </ul>
            </div>
        </section>
    );
}
