import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppConfigState {
    mode: "highlight-only" | "embedder-choice" | undefined;
    sessionIds: number[];
}

const initialState: AppConfigState = {
    mode: undefined,
    sessionIds: [],
};

const appConfigSlice = createSlice({
    name: "appConfig",
    initialState,
    reducers: {
        highlightModeEnabled(state) {
            state.mode = "highlight-only";
        },
        embedderChoiceModeEnabled(state, action: PayloadAction<number[]>) {
            state.mode = "embedder-choice";
            state.sessionIds = action.payload;
        },
    },
});

export default appConfigSlice;
