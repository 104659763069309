import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { linkToSession } from "../../util/links";

export default function SessionLink({ id, children }: { id: number; children: ReactNode }) {
    return (
        <Link to={linkToSession(id)} className="session-list-item__link">
            {children}
        </Link>
    );
}
