import { useSelector } from "react-redux";
import { Session } from "../store/model";
import { useChannels } from "./channelHooks";
import { RootState } from "../store";
import { useMemo } from "react";

export const useFilterExcludedChannelsIds = () => {
    return useSelector((state: RootState) => state.filters.excludedChannels);
};

export const useFilterVisibleChannels = () => {
    const excludedIds = useFilterExcludedChannelsIds();
    const channels = useChannels();

    return useMemo(
        () => channels.filter(({ id }) => !excludedIds.includes(id)).sort((a, b) => a.sort - b.sort),
        [channels, excludedIds],
    );
};

export const useFilterHiddenChannels = () => {
    const excludedIds = useFilterExcludedChannelsIds();
    const channels = useChannels();

    return useMemo(
        () => channels.filter(({ id }) => excludedIds.includes(id)).sort((a, b) => a.sort - b.sort),
        [channels, excludedIds],
    );
};

export const useFilterSessions = (sessions: Session[]) => {
    const excludedIds = useFilterExcludedChannelsIds();

    return useMemo(
        () => sessions.filter((session) => session.channel === undefined || !excludedIds.includes(session.channel.id)),
        [sessions, excludedIds],
    );
};
