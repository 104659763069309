import React, { ReactElement } from "react";
import SessionListItem from "./SessionListItem";
import { useEmbedderSelectedSessionsGroupedByDay } from "../hooks/sessionHooks";
import { Session } from "../store/model";

export default function SessionsEmbedderChoice(): ReactElement {
    const sessions = useEmbedderSelectedSessionsGroupedByDay();

    if (Object.keys(sessions).length === 0) {
        return <p>Keine Veranstaltungen gefunden.</p>;
    }

    return (
        <>
            {Object.keys(sessions).map((dateStr) => (
                <section key={dateStr} className="sessions-list-daily">
                    <h3 className="session-list__date">{dateStr}</h3>
                    <ul className="session-list">
                        {sessions[dateStr].map((session: Session) => (
                            <SessionListItem key={session.id} {...session} />
                        ))}
                    </ul>
                </section>
            ))}
        </>
    );
}
