import React, { createElement } from "react";
import { Session } from "../../store/model";
import ChannelBadge from "../ChannelBadge/ChannelBadge";
import "./SessionHeader.scss";

export const preEventIDs = [660, 661];

export interface SessionHeaderProps {
    session: Session;
    as: string;
}

function SessionHeader({ session, as }: SessionHeaderProps) {
    const preEventLabel = preEventIDs.includes(session.id);

    return createElement(
        as,
        { className: "session-header" },
        <>
            {preEventLabel && <span className="session-header__pre-event-badge">Pre-Event</span>}
            {session.highlight && <span className="session-header__tip-badge">Tipp</span>}
            {session.cancelled && <span className="session-cancelled">Abgesagt</span>}
            <span className="session-header__title">{session.title}</span>
            {session.channel && <ChannelBadge channel={session.channel} />}
        </>,
    );
}

export default SessionHeader;
