import React, { Fragment } from "react";

export default function LongDescription({ text }: { text: string }) {
    if (text === undefined) return null;
    return (
        <Fragment>
            {text.split("\n").map((item, key) => {
                return (
                    <Fragment key={key}>
                        {item}
                        <br />
                    </Fragment>
                );
            })}
        </Fragment>
    );
}
