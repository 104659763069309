import React from "react";
import { Host } from "../store/model";

export default function SessionHostInstagram({ host }: { host: Host }) {
    let instagramUrl = null;
    if (host.links !== undefined && host.links.instagram !== undefined) {
        instagramUrl = host.links.instagram;
    }

    if (instagramUrl === null) {
        return null;
    }

    return (
        <a href={instagramUrl} target="_blank" rel="noreferrer">
            <img src="/assets/instagram.svg" alt="Instagram" />
        </a>
    );
}
