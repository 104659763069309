import { Session } from "../store/model";
import { formatDate, formatTimestamp } from "./dateFormat";

export const sortSessionsByDate = (sessions: Session[]) => {
    return [...sessions].sort((a, b) => {
        return formatTimestamp(a.start) - formatTimestamp(b.start);
    });
};

export const groupSessionsByDate = (sessions: Session[]) => {
    const groups: { [key: string]: Session[] } = {};
    for (const session of sessions) {
        const date = formatDate(session.start);
        if (!Object.keys(groups).includes(date)) groups[date] = [];
        groups[date].push(session);
    }
    return groups;
};

export const filterSessionsByHost = (sessions: Session[], hostId: number) => {
    return sessions.filter((sessions) => sessions.host.id === hostId);
};
