import moment from "moment";
require("moment/locale/de");

// utc() hack - reinkommender zeitstempel ist schon korrekt und darf nicht umgerechnet werden

export const formatTypoDateTime = (stringDate: string) => {
    return moment(stringDate).format("LLLL");
};

export const formatTypoTime = (stringDate: string) => {
    return moment(stringDate).format("HH:mm");
};

export const formatDate = (stringDate: string) => {
    return moment(stringDate).format("l");
};

export const formatTimestamp = (stringDate: string) => {
    return Number(moment(stringDate).format("x"));
};

export const formatDay = (stringDate: string) => {
    return moment(stringDate).format("D");
};

export const formatWeekday = (stringDate: string) => {
    return moment(stringDate).format("dd");
};

export const formatMonth = (stringDate: string) => {
    return moment(stringDate).format("MMMM");
};

export const formatYear = (stringDate: string) => {
    return moment(stringDate).format("YYYY");
};
