import React from "react";
import Linkify from "react-linkify";
import { Host } from "../store/model";

export default function HostLink({ host }: { host: Host }) {
    let hostLink = null;

    if (host.links !== undefined && host.links.host !== undefined) {
        hostLink = host.links.host;
    }

    if (hostLink === null) {
        return null;
    }

    return (
        <div className="host-link">
            <Linkify>{hostLink}</Linkify>
        </div>
    );
}
