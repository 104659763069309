import { sortSessionsByDate, groupSessionsByDate, filterSessionsByHost } from "../util/transformations";
import { RootState } from "../store";
import { useSelector } from "react-redux";

export const useSessions = () => {
    return useSelector((state: RootState) => state.sessions.sessions);
};

export const useSession = (id: number) => {
    return useSessions().find((session) => id === session.id);
};

export const useSortedSessions = () => {
    return sortSessionsByDate(useSessions());
};

export const useSessionsGroupedByDay = () => {
    return groupSessionsByDate(useSortedSessions());
};

export const useSessionsByDay = (date: string) => {
    const groups = useSessionsGroupedByDay();
    return typeof groups[date] === typeof undefined ? [] : groups[date];
};

export const useHostSessions = (hostId: number) => {
    return filterSessionsByHost(useSessions(), hostId);
};

export const useHighlightSessionsGroupedByDay = () => {
    return groupSessionsByDate(sortSessionsByDate(useSessions().filter((x) => x.highlight)));
};

export const useEmbedderSelectedSessionsGroupedByDay = () => {
    const wantedIds = useSelector((state: RootState) => state.appConfig.sessionIds);
    return groupSessionsByDate(sortSessionsByDate(useSessions().filter((x) => wantedIds.includes(x.id))));
};
