import React from "react";
import Linkify from "react-linkify";

export default function EventRegistration({ links }: { links: { event?: string } }) {
    let eventRegistration = null;
    if (typeof links != typeof undefined && typeof links.event !== typeof undefined) {
        eventRegistration = links.event;
    }

    if (eventRegistration === null) {
        return null;
    }

    return (
        <li className="session-section__register">
            <strong>Anmeldung: </strong>
            <Linkify>{eventRegistration}</Linkify>
        </li>
    );
}
