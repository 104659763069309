import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import React from "react";
import { Session } from "../store/model";

export default function SessionMap({ location }: { location: Session["location"] }) {
    if (location === undefined || location.lat === undefined || location.lng === undefined) {
        return null;
    }

    return (
        <MapContainer className="session-map" center={[location.lat, location.lng]} zoom={13} style={{ height: 300 }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[location.lat, location.lng]}>
                <Popup>{location.name}</Popup>
            </Marker>
        </MapContainer>
    );
}
