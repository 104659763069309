import React from "react";
import { Host } from "../store/model";

export default function SessionHostTwitter({ host }: { host: Host }) {
    let twitterUrl = null;
    if (host.links !== undefined && host.links.twitter !== undefined) {
        twitterUrl = host.links.twitter;
    }

    if (twitterUrl === null) {
        return null;
    }

    return (
        <a href={twitterUrl} target="_blank" rel="noreferrer">
            <img src="/assets/twitter.svg" alt="Twitter" />
        </a>
    );
}
