import React from "react";
import { useParams } from "react-router-dom";
import { useSession } from "../../hooks/sessionHooks";
import OrganizationLink from "../links/OrganizationLink";
import LongDescription from "../LongDescription";
import { formatDate, formatTypoTime } from "../../util/dateFormat";
import EventRegistration from "../eventRegistration";
import SessionHostFacebook from "../SessionHostFacebook";
import SessionHostTwitter from "../SessionHostTwitter";
import HostLink from "../HostLink";
import SessionHostYoutube from "../SessionHostYoutube";
import SessionHostInstagram from "../SessionHostInstagram";
import SessionHostFediverse from "../SessionHostFediverse";
import SessionHostLinkedin from "../SessionHostLinkedin";
import SessionMap from "../SessionMap";
import SessionHostJobs from "../SessionJobNotice";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Header from "../Header/Header";
import SessionHeader from "../SessionHeader/SessionHeader";

export default function EventPage() {
    const { id: idStr } = useParams();

    if (idStr === undefined) {
        throw new Error("id cannot be undefined here");
    }

    const id = Number.parseInt(idStr, 10);
    const session = useSession(id);

    const isLoading = useSelector((state: RootState) => state.sessions.isLoading);

    if (isLoading) {
        return <p>Suche Veranstaltung...</p>;
    } else if (session === undefined) {
        return <p>Sorry, nichts zu finden hier...</p>;
    }

    let sessionHostLogo = null;
    if (typeof session.host.logo != typeof undefined && typeof session.host.logo !== typeof undefined) {
        sessionHostLogo = <img src={session.host.logo} alt="Logo" />;
    }

    return (
        <div className="session-page">
            <Header />
            <SessionHeader session={session} as="h1" />

            <p className="session-description-short">
                <LongDescription text={session.description?.short ?? ""} />
            </p>

            <p className="session-description-long">
                <LongDescription text={session.description?.long ?? ""} />
            </p>

            <div className="session-data">
                <div>
                    <h2>Veranstaltungsdaten</h2>
                    <ul className="session-meta-list">
                        <li>
                            <strong>Datum: </strong> {formatDate(session.start)}
                        </li>
                        <li>
                            <strong>Zeit: </strong> {formatTypoTime(session.start)}{" "}
                            {session.end ? `- ${formatTypoTime(session.end)}` : ""}
                        </li>

                        {session.onlineOnly ? (
                            <li className="onlineOnly">
                                <strong>Ort: </strong> Nur Online
                            </li>
                        ) : (
                            <li>
                                <strong>Ort: </strong>
                                {session.location?.name ?? ""}
                                <br />
                                {session.location?.streetNo ?? ""}
                                <br />
                                {session.location?.zipcode ?? ""} {session.location?.city ?? ""}
                            </li>
                        )}

                        {session.links && <EventRegistration links={session.links} />}

                        {session.location?.isAccessible && (
                            <li className="session-section__accessibilityHint">
                                Der Veranstaltungsort ist barrierefrei.
                            </li>
                        )}
                    </ul>
                </div>

                {!session.onlineOnly ? <SessionMap location={session.location} /> : null}
            </div>

            <div className="session-section__host">
                <div className="session-page__host">
                    <div className="session-page__host-logo">{sessionHostLogo}</div>
                    <div>
                        <h3 className="session-page__host-title">Veranstalter</h3>
                        <h2 className="session-page__host-headline">{session.host.name}</h2>

                        <div className="session-page__links">
                            <div className="session-page__links-text">
                                <HostLink host={session.host} />
                                <SessionHostJobs host={session.host} />
                            </div>

                            <span className="session-page__host-social">
                                <SessionHostFacebook host={session.host} />
                                <SessionHostTwitter host={session.host} />
                                <SessionHostYoutube host={session.host} />
                                <SessionHostInstagram host={session.host} />
                                <SessionHostFediverse host={session.host} />
                                <SessionHostLinkedin host={session.host} />
                            </span>
                        </div>

                        {session.host.infotext && <p>{session.host.infotext}</p>}
                        <p>
                            <OrganizationLink id={session.host.id}>
                                Weitere Termine von diesem Veranstalter
                            </OrganizationLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
