import React from "react";
import { Host } from "../store/model";

export default function SessionHostJobs({ host }: { host: Host }) {
    let jobsUrl = null;
    if (host.links !== undefined && host.links.jobs !== undefined) {
        jobsUrl = host.links.jobs;
    }

    if (jobsUrl === null) {
        return null;
    }

    return (
        <a href={jobsUrl} target="_blank" rel="noreferrer" className="job-notification">
            Offene Stellenangebote
        </a>
    );
}
