import React from "react";
import { Host } from "../store/model";

export default function SessionHostLinkedin({ host }: { host: Host }) {
    let linkedinUrl = null;
    if (host.links !== undefined && host.links.linkedIn !== undefined) {
        linkedinUrl = host.links.linkedIn;
    }

    if (linkedinUrl === null) {
        return null;
    }

    return (
        <a href={linkedinUrl} target="_blank" rel="noreferrer">
            <img src="/assets/linkedin.svg" alt="LinkedIn" />
        </a>
    );
}
