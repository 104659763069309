import React from "react";
import SessionsGroupedByDay from "../SessionsGroupedByDay";
import SessionsHighlightOnly from "../SessionsHighlightOnly";
import SessionsEmbedderChoice from "../SessionsEmbedderChoice";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function SessionsTable() {
    const isLoading = useSelector((state: RootState) => state.sessions.isLoading);
    const mode = useSelector((state: RootState) => state.appConfig.mode);

    if (isLoading) {
        return <p>Veranstaltungen werden geladen...</p>;
    }

    switch (mode) {
        case "highlight-only":
            return <SessionsHighlightOnly />;

        case "embedder-choice":
            return <SessionsEmbedderChoice />;

        default:
            return <SessionsGroupedByDay />;
    }
}
