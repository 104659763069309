import React from "react";
import { Host } from "../store/model";

export default function SessionHostYoutube({ host }: { host: Host }) {
    let youtubeUrl = null;
    if (host.links !== undefined && host.links.youtube !== undefined) {
        youtubeUrl = host.links.youtube;
    }

    if (youtubeUrl === null) {
        return null;
    }

    return (
        <a href={youtubeUrl} target="_blank" rel="noreferrer">
            <img src="/assets/youtube.svg" alt="Youtube" />
        </a>
    );
}
