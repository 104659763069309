import React, { useMemo, useState } from "react";
import { Route, Routes, NavLink, Navigate } from "react-router-dom";
import { formatDay, formatMonth, formatWeekday, formatYear } from "../util/dateFormat";
import { linkToSessionsDate } from "../util/links";
import { useSessionsGroupedByDay, useSessionsByDay, useSessions } from "../hooks/sessionHooks";
import SessionListItem from "./SessionListItem";
import { useFilterSessions } from "../hooks/filterHooks";
import ChannelFilter from "./ChannelFilter";
import { Session } from "../store/model";

const DailySessions = ({ date, search }: { date: string; search: string }) => {
    const sessionsByDay = useSessionsByDay(date);
    const allSessions = useSessions();
    const sessionsByGroupedDay = useSessionsGroupedByDay();

    const selectedSessions = useFilterSessions(search.trim() === "" ? sessionsByDay : allSessions);

    const searchWords = useMemo(
        () =>
            search
                .split(/\s+/)
                .map((word) => word.trim().toLocaleLowerCase())
                .filter((word) => !!word),
        [search],
    );

    const sessions = useMemo(() => {
        const results =
            searchWords.length === 0
                ? selectedSessions
                : selectedSessions.filter((session) =>
                      searchWords.some(
                          (word) =>
                              session.host.name.toLocaleLowerCase().includes(word) ||
                              session.title.toLocaleLowerCase().includes(word) ||
                              session.description?.short?.toLocaleLowerCase().includes(word),
                      ),
                  );

        if (results.length > 10 && !results.some((x) => x.id === 756)) {
            return [...results, ...selectedSessions.filter((x) => x.id === 756)];
        }

        return results;
    }, [selectedSessions, searchWords]);

    const sessionsWithDate = useMemo(
        () => getSessionsWithDate(sessions, sessionsByGroupedDay),
        [sessions, sessionsByGroupedDay],
    );

    if (sessions.length === 0) {
        const session = selectedSessions.find((x) => x.id === 784);

        return session ? (
            <>
                <p>Keine Veranstaltungen gefunden. Oder meintest du: 😉</p>
                <ul className="session-list">
                    <SessionListItem {...session} />
                </ul>
            </>
        ) : (
            <p>Keine Veranstaltungen gefunden.</p>
        );
    }

    return (
        <section className="sessions-list-daily">
            <ul className="session-list">
                {searchWords.length === 0
                    ? sessions.map((session) => <SessionListItem key={session.id} {...session} />)
                    : sessionsWithDate.map(([date, sessions]) => (
                          <>
                              {sessions.length > 0 ? <h3 className="session-list__date">{date}</h3> : <></>}
                              {sessions.map((session) => (
                                  <SessionListItem key={session.id} {...session} />
                              ))}
                          </>
                      ))}
            </ul>
        </section>
    );
};

function getSessionsWithDate(
    sessions: Session[],
    sessionsByGroupedDay: {
        [key: string]: Session[];
    },
): [string, Session[]][] {
    const sessionIds = sessions.map((s) => s.id);

    return Object.entries(sessionsByGroupedDay).map(([date, sessions]) => [
        date,
        sessions.filter((s) => sessionIds.includes(s.id)),
    ]);
}

export default function SessionsGroupedByDay() {
    const dayGroups = useSessionsGroupedByDay();

    const sessionDates = Object.keys(dayGroups);
    const [search, setSearch] = useState("");

    return (
        <div>
            <div className="search-filter">
                <input
                    className="search-filter__input"
                    type="text"
                    placeholder="Suche"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <nav className={`date-menu ${search.trim() === "" ? "" : "date-menu__disabled"}`}>
                <ul className="date-menu__list">
                    {sessionDates.map((date) => {
                        const rawDate = dayGroups[date][0].start;
                        return (
                            <li className="date-menu__item" key={date}>
                                <NavLink className="date-menu__link" replace={false} to={linkToSessionsDate(date)}>
                                    <span className="date-menu__weekday">{formatWeekday(rawDate)}</span>
                                    <span className="date-menu__day">{formatDay(rawDate)}</span>
                                    <span className="date-menu__month">{formatMonth(rawDate)}</span>
                                    <span className="date-menu__year">{formatYear(rawDate)}</span>
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </nav>

            <ChannelFilter />

            <Routes>
                {sessionDates.map((date) => {
                    return (
                        <Route
                            key={date}
                            path={linkToSessionsDate(date)}
                            element={<DailySessions date={date} search={search} />}
                        />
                    );
                })}

                <Route path="*" element={<Navigate replace to={linkToSessionsDate(sessionDates[0])} />} />
            </Routes>
        </div>
    );
}
