import React from "react";
import { createRoot } from "react-dom/client";
import store from "./store";

import { Provider } from "react-redux";
import { HashRouter, Routes, Route } from "react-router-dom";

import OrganizerPage from "./components/pages/OrganizerPage";
import SessionsPage from "./components/pages/SessionsPage";
import SessionPage from "./components/pages/SessionPage";
import { PATH_TO_EVENT, PATH_TO_ORGANIZER } from "./util/links";

import "./style/style.scss";

import sessionsSlice from "./store/slices/sessionsSlice";
import appConfigSlice from "./store/slices/appConfigSlice";

import "leaflet/dist/leaflet.css";

// work-around react-leaftlet icon issues, see https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
delete (window as any).L.Icon.Default.prototype._getIconUrl;
(window as any).L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const $app = document.getElementById("app");

if (!$app) {
    throw new Error("#app node missing");
}

switch ($app.getAttribute("data-mode")) {
    case "highlight-only":
        store.dispatch(appConfigSlice.actions.highlightModeEnabled());
        break;

    case "embedder-choice":
        const sessionIds = $app.getAttribute("data-session-ids");
        if (!sessionIds) {
            throw new Error("data-session-ids attribute missing");
        }

        store.dispatch(
            appConfigSlice.actions.embedderChoiceModeEnabled(sessionIds.split(",").map((x) => Number.parseInt(x, 10))),
        );
        break;

    default:
        break;
}

const { sessionsLoaded } = sessionsSlice.actions;

fetch("https://backend.timetable.wueww.de/export/session.json")
    .then((result) => result.json())
    .then((data) => {
        store.dispatch(sessionsLoaded(data));
    });

const root = createRoot($app);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <Routes>
                    <Route path={PATH_TO_ORGANIZER} element={<OrganizerPage />} />
                    <Route path={PATH_TO_EVENT} element={<SessionPage />} />

                    {/** 404 handling in EventsPage Route */}
                    <Route path="*" element={<SessionsPage />} />
                </Routes>
            </HashRouter>
        </Provider>
    </React.StrictMode>,
);
