import { createElement } from "react";
import { Channel } from "../../store/model";
import "./ChannelBadge.scss";
import clsx from "clsx";

export interface ChannelBadgeProps {
    as?: string;
    channel: Channel;
    onClick?: () => void;
    inActive?: boolean;
}

function ChannelBadge({ as = "span", channel, onClick, inActive }: ChannelBadgeProps) {
    return createElement(
        as,
        {
            className: clsx(
                "channel-badge",
                `channel-badge-${channel.id}`,
                onClick && "channel-badge--interactive",
                inActive && "channel-badge--inactive",
            ),
            onClick,
        },
        channel.name,
    );
}

export default ChannelBadge;
