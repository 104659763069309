import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FiltersState {
    excludedChannels: number[];
}

const initialState: FiltersState = {
    excludedChannels: [],
};

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        channelToggled(state, action: PayloadAction<number>) {
            const currentExcludedChannels = state.excludedChannels || [];
            const excludedChannels =
                currentExcludedChannels.indexOf(action.payload) === -1
                    ? [...currentExcludedChannels, action.payload]
                    : currentExcludedChannels.filter((x) => x !== action.payload);

            state.excludedChannels = excludedChannels;
        },
    },
});

export default filtersSlice;
