import React from "react";
import { useParams } from "react-router-dom";
import { useHost } from "../../hooks/hostHooks";
import { useHostSessions } from "../../hooks/sessionHooks";
import SessionListItem from "../SessionListItem";
import HostLink from "../HostLink";
import SessionHostFacebook from "../SessionHostFacebook";
import SessionHostTwitter from "../SessionHostTwitter";
import SessionHostYoutube from "../SessionHostYoutube";
import SessionHostInstagram from "../SessionHostInstagram";
import SessionHostFediverse from "../SessionHostFediverse";
import SessionHostLinkedin from "../SessionHostLinkedin";
import { Session } from "../../store/model";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Header from "../Header/Header";

import "./OrganizerPage.scss";

export default function OrganizerPage() {
    const { id: idStr } = useParams();

    if (idStr === undefined) {
        throw new Error("id cannot be undefined here");
    }

    const id = Number.parseInt(idStr, 10);
    const host = useHost(id);
    const sessions: Session[] = useHostSessions(id);

    const isLoading = useSelector((state: RootState) => state.sessions.isLoading);

    if (isLoading) {
        return <p>Suche Veranstaltung...</p>;
    } else if (host === undefined) {
        return <p>Sorry, nichts zu finden hier...</p>;
    }

    return (
        <div className="organizer-page">
            <Header />
            <h1 className="organizer-page__header">{host.name}</h1>

            <HostLink host={host} />
            <div className="session-page__host-social">
                <SessionHostFacebook host={host} />
                <SessionHostTwitter host={host} />
                <SessionHostYoutube host={host} />
                <SessionHostInstagram host={host} />
                <SessionHostFediverse host={host} />
                <SessionHostLinkedin host={host} />
            </div>

            {host.infotext && <p className="organizer-page__infotext">{host.infotext}</p>}

            <ul className="session-list">
                {sessions.map((session) => (
                    <SessionListItem key={session.id} {...session} />
                ))}
            </ul>
        </div>
    );
}
