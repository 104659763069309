import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.scss";

function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnBack = useCallback(() => {
        if (location.key === "default") {
            navigate("/");
        } else {
            navigate(-1);
        }
    }, [location.key, navigate]);

    return (
        <header>
            <button onClick={handleOnBack} className="header__back-button">
                zurück
            </button>
        </header>
    );
}

export default Header;
