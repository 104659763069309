import React from "react";
import { Host } from "../store/model";

export default function SessionHostFacebook({ host }: { host: Host }) {
    let facebookUrl = null;
    if (host.links !== undefined && host.links.facebook !== undefined) {
        facebookUrl = host.links.facebook;
    }

    if (facebookUrl === null) {
        return null;
    }

    return (
        <a href={facebookUrl} target="_blank" rel="noreferrer">
            <img src="/assets/facebook.svg" alt="Facebook" />
        </a>
    );
}
