import React from "react";
import { Host } from "../store/model";

export default function SessionHostFediverse({ host }: { host: Host }) {
    let fediverseUrl = null;
    if (host.links !== undefined && host.links.fediverseUrl !== undefined) {
        fediverseUrl = host.links.fediverseUrl;
    }

    if (fediverseUrl === null) {
        return null;
    }

    return (
        <a href={fediverseUrl} target="_blank" rel="noreferrer">
            <img src="/assets/fediverse.svg" alt="Fediverse" />
        </a>
    );
}
