import { combineReducers, configureStore } from "@reduxjs/toolkit";
import sessionsSlice from "./slices/sessionsSlice";
import filtersSlice from "./slices/filtersSlice";
import appConfigSlice from "./slices/appConfigSlice";

const rootReducer = combineReducers({
    appConfig: appConfigSlice.reducer,
    filters: filtersSlice.reducer,
    sessions: sessionsSlice.reducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
