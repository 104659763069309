import { Session } from "../store/model";
import { formatDay, formatMonth, formatTypoTime } from "../util/dateFormat";
import SessionHeader from "./SessionHeader/SessionHeader";
import SessionLink from "./links/SessionLink";
import React from "react";

export const preEventIDs = [660, 661];

export default function SessionListItem(session: Session) {
    const { id, start, end, host, description } = session;

    const endDateString = end ? ` - ${formatTypoTime(end)} Uhr` : "";

    return (
        <li key={id} className="session-list-item">
            <SessionLink id={id}>
                <SessionHeader session={session} as="header" />

                <span className="session-list-item__host">{host.name}</span>

                <span className="session-list-item__date">
                    <span className="session-list-item__date-full">
                        {formatDay(start)}. {formatMonth(start)} —{" "}
                    </span>
                    {formatTypoTime(start)} Uhr
                    {endDateString}
                </span>

                <span className="session-list-description">
                    {description?.short ?? ""} <span className="session-cta">mehr</span>
                </span>
            </SessionLink>
        </li>
    );
}
